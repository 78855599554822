import * as React from "react";

import Layout from "../components/Layout/Layout";
import InProcess from "../components/inProcess/InProcess";

const inProcess = () => {
  return (
    <Layout>
      <InProcess />
    </Layout>
  );
};

export default inProcess;
