import * as React from "react";
import Boy from "../../images/boy.png";

import { injectIntl } from "gatsby-plugin-intl";

// @ts-ignore
const InProcess = ({ intl }) => {
  return (
    <div className="flex items-center justify-center bg-[#FAFAFA]">
      <div className="flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
        <div className="mt-3 flex flex-col text-left mb-16 font-[gabdugi] items-center">
          <h2 className="text-[#DA6A00] font-bold  text-[25px] text-center">
            {intl.formatMessage({ id: "InProcess.Title" })}
          </h2>
          <img
            src={Boy}
            alt="Boy"
            className="w-[400px] h-[400px]"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(InProcess);
